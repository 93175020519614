import Groupxpng from "../../img/group@2x.png";
import ProgressLoader from "../../svgfile/progressLoader.svg";
const ForgotPasswordLoaderContainer = (props) => {
  const { title, message } = props;
  return (
    <div class="d-flex">
      <div className="empty-img-container"></div>
      <div className="cstpx-10 modal-box">
        <div className="">
          <div className="sign-in-group-parent">
            {/* siginParent-position */}
            <img className="sign-in-group-icon" alt="" src={Groupxpng} />
            <div className="sign-in-successful-parent">
              <div className="sign-in-successful">{title}!</div>
              <div className="sign-in-please-wait-you-container">
                <p className="sign-in-please-wait">Please wait.....</p>
                <p className="sign-in-please-wait">{message}</p>
              </div>
            </div>
            <div>
              <img
                className="sign-in-group-icon1"
                alt=""
                src={ProgressLoader}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordLoaderContainer;
