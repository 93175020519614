import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TwiterLinkImg from "../img/lux_tweet.png";
import useInititateTwitterLink from "../services/useInitiateTwiiterLink";
import { useSearchParams } from "react-router-dom";
import useAuth from "../services/useAuth";
import { useForm } from "react-hook-form";
const stepTitles = ["Link Twitter", "Tweet Settings"];
const XsetupModal = ({ showTwiterModaldModal, setshowTwiterModaldModal }) => {
  const { register, handleSubmit, reset } = useForm();
  const token = JSON.parse(useAuth()?.token);
  const [twitterLink, initiateTwitterLink] = useInititateTwitterLink(
    setshowTwiterModaldModal
  );
  let [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [twitterUserExists, setTwitterUserExists] = useState(false);
  const numSteps = stepTitles.length;
  useEffect(() => {
    const setDialog = searchParams.get("setDialog");
    const setTwitterSetupStep = searchParams.get("setTwitterSetupStep");
    if (setDialog === "autoTweetsStepper") {
      setshowTwiterModaldModal(true);
      if (setTwitterSetupStep === "1") {
        setCurrentStep(1);
      } else if (setTwitterSetupStep === "2") {
        setCurrentStep(2);
      }
    } else {
      setshowTwiterModaldModal(false);
    }
  }, [searchParams, setshowTwiterModaldModal]);
  // Fetch Twitter user data when modal opens
  useEffect(() => {
    const fetchTwitterUser = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LOCAL_API_URL}/twitter/get-tweet-pesudo`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        console.log(data);
        if (data.status === "ok" && data.twitterUser) {
          setTwitterUserExists(true);
          setCurrentStep(2);
          reset(data.twitterUser);
        }
      } catch (error) {
        console.error("Error fetching Twitter user:", error);
      }
    };
    if (showTwiterModaldModal) {
      fetchTwitterUser();
    }
  }, [showTwiterModaldModal, token, reset]);
  const handleCloseTwitterModal = () => {
    setshowTwiterModaldModal(false);
  };
  const initiateTweeiLink = () => {
    window.location.href = `${process.env.REACT_APP_LOCAL_API_URL}/twitter/auth?token=${token}`;
  };
  // Unlink Twitter account function
  const unlinkTwitterAccount = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LOCAL_API_URL}/twitter/unlink-twitter`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        setTwitterUserExists(false);
        
        reset(); // Reset the form if needed
        alert("Twitter account unlinked successfully.");
        setCurrentStep(1);
      } else {
        alert("Failed to unlink Twitter account.");
      }
    } catch (error) {
      console.error("Error unlinking Twitter account:", error);
      alert("An error occurred while unlinking the Twitter account.");
    }
  };
  const steps = Array.from({ length: numSteps }, (_, index) => index + 1);
  return (
    <div>
      <Modal
        show={showTwiterModaldModal}
        onHide={handleCloseTwitterModal}
        id="modal-container"
        centered
      >
        <div>
          <h5 className="sec-head">Twitter Integration</h5>
          <div className="step-container">
            <div className="stepper-horizontal" id="stepper1">
              {steps.map((stepNum) => (
                <div
                  key={stepNum}
                  className={`step ${
                    currentStep === stepNum ? "editing" : ""
                  } ${currentStep > stepNum ? "done" : ""}`}
                >
                  <div className="step-circle">
                    <span className="span">{stepNum}</span>
                  </div>
                  <div className="step-title">{stepTitles[stepNum - 1]}</div>
                  <div className="step-bar-left"></div>
                  <div className="step-bar-right"></div>
                </div>
              ))}
            </div>
          </div>
          {/* Step one */}
          {currentStep === 1 && (
            <div className="step-body">
              <div className="step-heading typeo-32-38">
                Step 1: Link Twitter
              </div>
              <p className="step-one-info typeo-18-24-u mb-0">
                Set Up Twitter To Auto Tweet When You Receive A Gift.
              </p>
              <div className="step-one-sub-body">
                <img src={TwiterLinkImg} alt="Twitter Link" />
              </div>
              <div className="social-btn-group">
                <button
                  className="btn50 save-social-btn goal-title text-white all-btn"
                  onClick={initiateTweeiLink}
                >
                  {twitterLink.loading ? "Loading..." : "Link Twitter"}
                </button>
              </div>
            </div>
          )}
          {/* Step two */}
          {currentStep === 2 && (
            <form
              className="step-body"
              onSubmit={handleSubmit(initiateTwitterLink)}
            >
              <div className="step-heading typeo-32-38">
                Step 2: Tweet Settings
              </div>
              <div className="step-one-info mb-0 mx-auto">
                <label>Configure your tweet settings</label>
                <div className="text-start">
                  <input
                    type="checkbox"
                    name="pseudonym"
                    id="pseudonym"
                    className="me-2"
                    {...register("pseudonym")}
                  />
                  <label htmlFor="pseudonym">Always hide pseudonym</label>
                </div>
                <div className="text-start">
                  <input
                    type="checkbox"
                    name="includeGiftimage"
                    id="includeGiftimage"
                    className="me-2"
                    {...register("includeGiftimage")}
                  />
                  <label htmlFor="includeGiftimage">Include Gift Image</label>
                </div>
                <div className="text-start">
                  <input
                    type="checkbox"
                    name="includeWishlistLink"
                    id="includeWishlistLink"
                    className="me-2"
                    {...register("includeWishlistLink")}
                  />
                  <label htmlFor="includeWishlistLink">
                    Include Wishlist Link
                  </label>
                </div>
              </div>
              <div className="twitterSetup-surprise">
                <label
                  htmlFor="minAmount"
                  className="typo-grey-16-600 text-black mb-1"
                >
                  Minimum gift amount
                </label>
                <input
                  type="text"
                  name="minimum_gift_amount"
                  className="form-control"
                  id="minAmount"
                  placeholder="$ 0.00"
                  {...register("minimum_gift_amount")}
                />
              </div>
              <div className="step-one-sub-body">
                <img src={TwiterLinkImg} alt="Twitter Settings" />
              </div>
              <div className="social-btn-group">
                <button
                  className="btn50 save-social-btn goal-title text-white all-btn"
                  disabled={twitterLink.loading}
                  type="submit"
                >
                  {twitterLink.loading ? "Loading..." : "Save Twitter Account"}
                </button>
                {twitterUserExists && (
                  <button
                    type="button"
                    className="btn50 save-social-btn goal-title text-white all-btn"
                    onClick={unlinkTwitterAccount}
                  >
                    Unlink Account
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default XsetupModal;
