import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BackBTn, EmailIcon, LockIcon } from "../elements/SvgElements";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { AuthApi } from "../config/axiosUtils";
import { useLoading } from "../features/loadingHooks";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../features/authSlice";
import { setCart } from "../features/cartSlice";
import SiginLoader from "../components/CustomLoader/SiginLoader";

const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { globalLoading, startGloablLoading, stopGlobalLoading } = useLoading();
  const [showPassword, setShowPassword] = useState(false);
  const [siginError, setSiginError] = useState("");
  const dispatch = useDispatch();

  const from = location.state?.from?.pathname || "/";
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle autofill for email and password
  useEffect(() => {
    setTimeout(() => {
      const email = document.querySelector('input[name="email"]');
      const password = document.querySelector('input[name="password"]');
      
      if (email && email.value) setValue("email", email.value);
      if (password && password.value) setValue("password", password.value);
    }, 500);
  }, [setValue]);

  async function handelLogin(formData) {
    setSiginError("");
    startGloablLoading();
    try {
      const res = await AuthApi.signin(formData);
      let { status, data } = res;
      if (status === 200) {
        console.log(data.message);
        toast.success(data.message);
        dispatch(
          login({
            user: JSON.stringify(data.user),
            token: JSON.stringify(data.token),
          })
        );
        dispatch(
          setCart({
            cart: res?.data?.user?.cart,
          })
        );
        localStorage.setItem("user-id", data.token);
        navigate(from, { replace: true });
      }
    } catch (e) {
      console.log("err", e);
      setSiginError(
        e?.response?.data?.message || e?.response?.data?.error || e?.message
      );
    } finally {
      stopGlobalLoading();
    }
  }

  return (
    <div className="position-relative">
      <div className="form-start-tab">
        <div className="form-brand-ct">
          <span className="back-i-btn">
            <button className="backbtn" type="button">
              <Link to="..">{BackBTn}</Link>
            </button>
          </span>
          <div className="login-view mt-4 text-left">
            <h2 className="sub-head">Welcome back</h2>
            <p className="para mt-2 text-left">
              Please enter your email & password to sign in.
            </p>
          </div>
          <div>
            <div className="form-start-login">
              <div className="form-brand">
                <form
                  onSubmit={handleSubmit(handelLogin)}
                  autoComplete="off"
                  className="user-login-form col-md-12 col-sm-12 col-xs-12 col-lg-12"
                >
                  {/* Email Input */}
                  <div className="form-group text-left">
                    <label className="form-head mb-2">Email</label>
                    <div
                      className={`inputWithIcon email-input ${
                        errors?.email ? "error-border" : ""
                      } `}
                    >
                      <input
                        type="text"
                        autoComplete="off"
                        autoFocus="autofocus"
                        className={`${
                          errors?.email ? "error-placeholder" : ""
                        }`}
                        placeholder="Enter your email"
                        {...register("email", {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Enter a Valid Email",
                          },
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                        })}
                      />
                      {EmailIcon}
                    </div>
                    {errors?.email && (
                      <small className="error-message">
                        {errors?.email?.message}
                      </small>
                    )}
                  </div>

                  {/* Password Input */}
                  <div className="form-group text-left mt-2">
                    <label className="form-head mb-2">Password</label>
                    <div
                      className={`password-input ${
                        errors?.password ? "error-border" : ""
                      }`}
                    >
                      {LockIcon}
                      <input
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password" // Prevent autofill issues
                        className={`${
                          !showPassword.password &&
                          watch("password")?.length > 0
                            ? "if-password"
                            : null
                        }`}
                        placeholder="Enter your password "
                        {...register("password", {
                          minLength: {
                            value: 6,
                            message:
                              "Password Should be More Than 6 Characters",
                          },
                          required: {
                            value: true,
                            message: "Password is Required",
                          },
                        })}
                      />
                      {watch("password")?.length > 0 && (
                        <button
                          type="button"
                          className="showHideIcon"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <FaEye color="#9E9E9E" size={20} />
                          ) : (
                            <FaEyeSlash color="#9E9E9E" size={20} />
                          )}
                        </button>
                      )}
                    </div>
                    {errors?.password && (
                      <small className="error-message">
                        {errors?.password?.message}
                      </small>
                    )}
                  </div>

                  <div className="d-flex justify-space-between mt-2 w-nowrap">
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input "
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label check-label mx-1-75"
                        htmlFor="flexCheckDefault"
                      >
                        Remember Me
                      </label>
                    </div>

                    <div className="form-check mt-2 mb-2">
                      <Link to="/forgot-password" className="forget-password">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>

                  <div className="log-info mt-2 mb-3">
                    <small className="account-f">
                      Don’t have an account?{" "}
                      <span className="">
                        <Link className="forget-password" to="/">
                          Sign up
                        </Link>
                      </span>
                    </small>
                  </div>

                  <div className="account-switch">
                    <button
                      className={`long-btn-sign-in mt-2 mx-auto ${
                        globalLoading ? "long-btn-sign-in-disabled" : null
                      }`}
                      disabled={globalLoading}
                      type="submit"
                    >
                      {!globalLoading ? "Sign in" : "loading..."}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <span className="text-center d-block">
            <strong className="text-danger">{siginError && siginError}</strong>
          </span>
        </div>
      </div>
      {globalLoading && (
        <div className="over-lay active">
          <SiginLoader />
        </div>
      )}
    </div>
  );
};

export default SignIn;
