import { useState } from "react";
import useAuth from "./useAuth";
import { twitterApi } from "../config/axiosUtils";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const useInititateTwitterLink = (setCurrentStep) => {
  let [_, setSearchParams] = useSearchParams();
  const token = JSON.parse(useAuth()?.token);
  const [twitterLink, setTwitterLink] = useState({
    loading: false,
    error: false,
    data: null,
  });

  const initiateTwitterLink = async (data) => {
    setTwitterLink({ loading: true, error: false, data: null });
    try {
      // Redirect the user to the backend route
      const response = await twitterApi.setupTwiiterStepTwo(token, data);
      console.log(response);
      setTwitterLink({ loading: false, error: false, data: null });
      setSearchParams("");
      toast.success("Twitter account linked successfully");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occurred");
      setTwitterLink({ loading: false, error: true, data: null });
    }
  };

  return [twitterLink, initiateTwitterLink];
};

export default useInititateTwitterLink;
