import axios from "axios";
const tokenExpiredMessage = [
  "Token has expired.",
  "Invalid token.",
  "Not authenticated.",
];
const api = axios.create({
  baseURL: process.env.REACT_APP_LOCAL_API_URL,
});

// Response Interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorObj = {};
    if (error.response && error.response.data) {
      errorObj["status"] = error.response.status;
      errorObj["message"] = error.response.data.message;
    }else{
      errorObj['message'] = error.message
    }
    if (tokenExpiredMessage.includes(errorObj.message) === true) {
      window.localStorage.clear();
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  }
);

export const AdminApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
});

export { api };
