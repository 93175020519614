import React, { useEffect, useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { MdBugReport } from "react-icons/md";
import { useSelector } from "react-redux";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SendSurpriseModal from "../components/SendSurpriseModal";
import { userApi } from "../config/axiosUtils";
import useAuth from "../services/useAuth";
import { useLoading } from "../features/loadingHooks";
import { toast } from "react-toastify";
import castDate, { getSocialIcon, imgBasePath } from "../utils/Helper";
import { filterIcon } from "../elements/SvgElements";
import { useCategory } from "../features/categoryHooks";
import { Dropdown } from "react-bootstrap";
import useFollowed from "../services/useFollowed";
import { setFollowed } from "../features/fetchFollowedSlice";
import { useDispatch } from "react-redux";
import OtherFighterGoals from "../components/OtherFighterGoals";
import ProfileShimmer from "../shimmer/ProfileShimmer";
import FlagModal from "../components/FlagModal";
import Tooltip from "../components/TootlTip";
import useToken from "../services/useToken";
import { useNavigate } from "react-router-dom";
//<MdBugReport />;

const OtherFighterProfile = () => {
  const navigate = useNavigate()
  const following = useFollowed()?.followed;
  const [currencyDetails, setCurrencyDetails] = useState(null);
  const [currentUserCurrency, setCurrentUserCurrency] = useState(null);
  const [showFlagModal, setShowFlagModal] = useState(false);
  const currency = useSelector((state) => state?.currency?.currency);
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [sendShowSurpriseModal, setSendShowSurpriseModal] = useState(false);
  const categories = useCategory();
  const [searchParams, setSearchParams] = useSearchParams();
  const localStorageToken = useToken()
  const [fighterData, setFighterData] = useState({
    data: {},
    status: "",
    message: "",
  });

  const token = JSON.parse(useAuth()?.token);
  const user = JSON.parse(useAuth().user);
  const userName = useParams()?.userName;
  const { globalLoading, startGloablLoading, stopGlobalLoading } = useLoading();

  // this function is used to fetched the fighter data
  const loadFighterData = async () => {
    startGloablLoading();
    try {
      const res = await userApi.getFitherDetails(
        token,
        userName,
        searchParams.get("sort") || "",
        searchParams.get("category") || ""
      );

      if (res?.status === 200) {
        setFighterData({
          data: res?.data?.data[0],
          status: "success",
          message: res?.data?.message,
        });
      }
    } catch (e) {
      if (e?.response?.status === 401) {
        toast.error(e?.response?.data?.message);
        setFighterData({
          data: {},
          status: "error",
          message: e?.response?.data?.message,
        });
      }
      if (e?.response?.status === 500) {
        toast.error(e?.response?.data?.message);
        setFighterData({
          data: {},
          status: "error",
          message: e?.response?.data?.message,
        });
      }
    } finally {
      stopGlobalLoading();
    }
  };

  function handleFilter(filterType, value) {
    setSearchParams((prev) => {
      if (filterType === "price-sort") {
        prev.set("sort", value);
      } else {
        prev.set("category", value);
      }
      return prev;
    });
  }

  //function to follow and unfollow fighter
  const followUnfollow = async (fighterId, action) => {
    if (!localStorageToken) {
      return navigate("/signin")
    }
    startGloablLoading();
    try {
      const res = await userApi.followUnfollow(token, fighterId, action);
      console.log(res);
      if (res?.status === 200) {
        dispatch(
          setFollowed({
            followed: res?.data?.updatedFollower,
          })
        );
        return toast.success(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      if (e?.response?.status === 401) {
        toast.error("Something went wrong try later");
      }
      if (e?.response?.status === 500) {
        toast.error(e?.response?.data?.message);
      }
      if (e?.response?.status === 400) {
        toast.error(e?.response?.data?.message);
      }
    } finally {
      stopGlobalLoading();
    }
  };

  // function to hanlde the surprise modal 

  const handleSurpirseModa = () =>{
    if (!localStorageToken) {
      return navigate("/signin")
    }
    setSendShowSurpriseModal(true)
  }

  useEffect(() => {
    loadFighterData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, searchParams.get("sort"), searchParams.get("category")]);

  useEffect(() => {
    if (fighterData?.data?._id) {
      const data = currency?.find(
        (item) => item.short_code === fighterData?.data?.currency
      );
      setCurrencyDetails(data); 
    }

    if (user?.currency) {
      const data = currency?.find((item) => item.short_code === user?.currency);
      setCurrentUserCurrency(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fighterData?.data?._id]);

  if (globalLoading) {
    return <ProfileShimmer />;
  }



  return (
    <>
      <main className="main-content mt-2">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
            <div className="tile">
              <div className="tile-content">
                <div className="user-banner-image">
                  {fighterData?.data?.bannerImage ? (
                    <img
                      loading="lazy"
                      alt="banner"
                      src={
                        fighterData?.data?.bannerImage
                          ? `${imgBasePath}/${fighterData?.data?.bannerImage}`
                          : "https://cdn.builder.io/api/v1/image/assets/TEMP/ce2fa3ea-01f4-4d9e-abfc-255dccdd8f17?apiKey=a05c6109e97c4bde98e757ca99d37c45&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce2fa3ea-01f4-4d9e-abfc-255dccdd8f17?apiKey=a05c6109e97c4bde98e757ca99d37c45&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce2fa3ea-01f4-4d9e-abfc-255dccdd8f17?apiKey=a05c6109e97c4bde98e757ca99d37c45&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce2fa3ea-01f4-4d9e-abfc-255dccdd8f17?apiKey=a05c6109e97c4bde98e757ca99d37c45&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce2fa3ea-01f4-4d9e-abfc-255dccdd8f17?apiKey=a05c6109e97c4bde98e757ca99d37c45&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce2fa3ea-01f4-4d9e-abfc-255dccdd8f17?apiKey=a05c6109e97c4bde98e757ca99d37c45&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce2fa3ea-01f4-4d9e-abfc-255dccdd8f17?apiKey=a05c6109e97c4bde98e757ca99d37c45&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce2fa3ea-01f4-4d9e-abfc-255dccdd8f17?apiKey=a05c6109e97c4bde98e757ca99d37c45&"
                      }
                      className="img u-banner"
                    />
                  ) : (
                    <div className="empty-bannerImage"></div>
                  )}

                  <div className="user-profile-container">
                    <img
                      src={`${imgBasePath}/${fighterData?.data?.profileImage}`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="user-info">
                  <div className="user-content">
                    <div className="user-details">
                      <div className="user-name sec-head">
                        <span className="text-capitalize">

                        {fighterData?.data?.firstName}{" "}
                        {fighterData?.data?.lastName}
                        </span>
                        <Tooltip content="Click to report!">
                          <button
                            onClick={() => setShowFlagModal(true)}
                            className="border-0 bg-transparent"
                          >
                            <MdBugReport />
                          </button>
                        </Tooltip>
                        {/* for mobile */}
                        <div className="d-lg-none d-mb-block">
                          <div className="d-flex gap-1 align-items-center justify-content-end">
                            {fighterData?.data?.socialLinks?.map(
                              (link) =>
                                link?.status === true && (
                                  <Link
                                    to={link?.link}
                                    key={link._id}
                                    target="_blank"
                                    className="social-media-links"
                                  >
                                    {getSocialIcon(link?.platform)}
                                  </Link>
                                )
                            )}
                          </div>
                        </div>
                        {/* for mobile */}
                      </div>
                      <div className="user-handle">
                        {fighterData?.data?.userName}
                      </div>
                      <div className="user-link profile-details">
                        https://www.fightcompanion.io/{" "}
                        {fighterData?.data?.userName}
                      </div>
                      <div className="promotion-company profile-details">
                        Fight Promation Company:{" "}
                        {fighterData?.data?.promotionCompany}
                      </div>
                    </div>
                    <div className="profilelink-copy-btn">
                      <IoCopyOutline
                        className="img-2"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://www.fightcompanion.io/preview/
                        ${fighterData?.data?.userName}`
                          );
                          toast.info("Copied to clipboard");
                        }}
                      />
                    </div>
                  </div>
                  <div className="log-user-info-right d-mb-none">
                    <div className="fighter-top text-end">
                      {following?.some(
                        (localElement) =>
                          localElement?._id === fighterData?.data?._id
                      ) ? (
                        <button
                          className="follow-button fav-btn"
                          disabled={globalLoading}
                          onClick={() =>
                            followUnfollow(fighterData?.data?._id, "unfollow")
                          }
                        >
                          Following
                        </button>
                      ) : (
                        <button
                          className="follow-button fav-btn"
                          disabled={globalLoading}
                          onClick={() =>
                            followUnfollow(fighterData?.data?._id, "follow")
                          }
                        >
                          Follow
                        </button>
                      )}
                    </div>
                    <div className="fighter-bottom d-flex gap-2 flex-column">
                      <div className="d-flex align-items-center gap-1">
                        <FaRegCalendarAlt size={23} /> Joined{" "}
                        {castDate(fighterData?.data?.createdAt)}
                      </div>
                      <div className="d-flex gap-1 align-items-center justify-content-end">
                        {fighterData?.data?.socialLinks?.map(
                          (link) =>
                            link?.status === true && (
                              <Link
                                to={link?.link}
                                key={link._id}
                                target="_blank"
                                className="social-media-links"
                              >
                                {getSocialIcon(link?.platform)}
                              </Link>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* for mobile detail favourite  */}
                <div className=" d-lg-none d-mb-block f-mb-detail">
                  <div className="align-items-center d-mb-flex">
                    <FaRegCalendarAlt size={23} /> Joined{" "}
                    {castDate(fighterData?.data?.createdAt)}
                    <div>
                      <div className="">
                        {following?.some(
                          (localElement) =>
                            localElement?._id === fighterData?.data?._id
                        ) ? (
                          <button
                            className="follow-button fav-btn"
                            disabled={globalLoading}
                            onClick={() =>
                              followUnfollow(fighterData?.data?._id, "unfollow")
                            }
                          >
                            Following
                          </button>
                        ) : (
                          <button
                            className="follow-button fav-btn"
                            disabled={globalLoading}
                            onClick={() =>
                              followUnfollow(fighterData?.data?._id, "follow")
                            }
                          >
                            Follow
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* follow buton */}
                </div>

                {/* for mobile ened here */}
              </div>

              <div className="filter-goal">
                <div className="surprise-goal">
                  <button
                    className="follow-button fav-btn"
                    onClick={() => handleSurpirseModa()}
                  >
                    Send Surprise
                  </button>

                  <div className="filterLeft">
                    <Dropdown>
                      <Dropdown.Toggle id="category-dropdown" variant="none">
                        <div
                          type="button"
                          className="category-btn sub-btn-fonts"
                          role="button"
                        >
                          Category
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu category-dropdown">
                        {categories?.map((element) => (
                          <Dropdown.Item
                            key={element?._id}
                            className={`dropdown-item ${
                              searchParams?.get("category") ===
                                element?.categoryName && "slected-filter"
                            }`}
                            role="button"
                            onClick={() => {
                              handleFilter("category", element?.categoryName);
                            }}
                          >
                            {element.categoryName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                      <Dropdown.Toggle id="filter-dropdown" variant="none">
                        <div
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {filterIcon}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu category-dropdown">
                        <Dropdown.Item
                          className={`dropdown-item ${
                            searchParams?.get("sort") === "Default" &&
                            "slected-filter"
                          }`}
                          role="button"
                          onClick={(e) => {
                            handleFilter("price-sort", e?.target?.innerHTML);
                          }}
                        >
                          Default
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`dropdown-item ${
                            searchParams?.get("sort") === "High to Low" &&
                            "slected-filter"
                          }`}
                          role="button"
                          onClick={(e) => {
                            handleFilter("price-sort", "High to Low");
                          }}
                        >
                          Price: High to Low
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`dropdown-item ${
                            searchParams?.get("sort") === "Low to High" &&
                            "slected-filter"
                          }`}
                          role="button"
                          onClick={(e) => {
                            handleFilter("price-sort", "Low to High");
                          }}
                        >
                          Price: Low to High
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`dropdown-item ${
                            searchParams?.get("sort") === "Most Recent" &&
                            "slected-filter"
                          }`}
                          role="button"
                          onClick={(e) => {
                            handleFilter("price-sort", e?.target?.innerHTML);
                          }}
                        >
                          Most Recent
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={`dropdown-item ${
                            searchParams?.get("sort") === "Oldest" &&
                            "slected-filter"
                          }`}
                          role="button"
                          onClick={(e) => {
                            handleFilter("price-sort", e?.target?.innerHTML);
                          }}
                        >
                          Oldest
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* card section-start */}

          <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
            {fighterData?.data?.goals?.length > 0 && !globalLoading ? (
              <OtherFighterGoals
                goals={fighterData?.data?.goals}
                currency={fighterData?.data?.currency} // goal currency
                userName={fighterData?.data?.userName}
                currencyDetails={currencyDetails}
                currentUserCurrency={currentUserCurrency} // current user or fighter currency
              />
            ) : (
              <div className="text-center">
                {" "}
                This fighter does not have any Goals
              </div>
            )}
          </div>

          <SendSurpriseModal
            sendShowSurpriseModal={sendShowSurpriseModal}
            setSendShowSurpriseModal={setSendShowSurpriseModal}
            surprisePrice={fighterData?.data?.surpriceContributionAmount}
            name={`${fighterData?.data?.firstName} ${fighterData?.data?.lastName}`}
            userId={fighterData?.data?._id}
            currency={fighterData?.data?.currency}
            token={token}
            currencyDetails={currencyDetails}
          />
          {/* card section-end-here */}
        </div>
      </main>

      {showFlagModal && (
        <FlagModal
          showFlagModal={showFlagModal}
          setShowFlagModal={setShowFlagModal}
          fighterId={fighterData?.data?._id}
          senderId={user?._id}
          
        />
      )}
    </>
  );
};

export default OtherFighterProfile;
