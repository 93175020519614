import React from "react";
import { Link } from "react-router-dom";
import { RiCheckboxCircleFill } from "react-icons/ri";
const FighterAccountComplete = () => {
  return (
    <div className="form-brand-ct">
      <form className="user-login-form" id="signup-btn">
        <div className="form-start-login">
          <RiCheckboxCircleFill size={70} />
          <h2 className="my-4">Verification Pending</h2>
          <p className="lh-lg">Your account is pending verification. Please check back in 2 business days.</p>
        </div>
        <div className="account-switch mt-4">
          <Link
            type="submit"
            to={`/signin`}
            className={`long-btn-sign-in mt-4 mx-auto text-decoration-none`}
          >
            Go to My Account
          </Link>
        </div>
      </form>
    </div>
  );
};

export default FighterAccountComplete;