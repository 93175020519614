import { useState, useCallback } from "react";
import { userApi } from "../config/axiosUtils";

const useUsernameUniqueness = () => {
  const [isUnique, setIsUnique] = useState(true);
  const [loading, setLoading] = useState(false);

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const checkUsernameUniqueness = async (query) => {
    try {
      setLoading(true); // Optional: Set loading state
      const res = await userApi.checkUsername(query);
      setIsUnique(res.data.isUnique);
    } catch (error) {
      setIsUnique(false);
    } finally {
      setLoading(false); // Optional: Reset loading state
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCheckUsernameUniqueness = useCallback(
    debounce(checkUsernameUniqueness, 600), // 500ms delay
    []
  );

  return [isUnique, debouncedCheckUsernameUniqueness, loading];
};

export default useUsernameUniqueness;
