import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { orderApi, userApi } from "../config/axiosUtils";
import { toast } from "react-toastify";
import useAuth from "../services/useAuth";
import { useDispatch } from "react-redux";
import { setFollowed } from "../features/fetchFollowedSlice";
import { imgBasePath } from "../utils/Helper";
import defaultImag from "../img/group@2x.png";

const RightAside = () => {
  const [loading, setLoading] = useState(false);
  const [migthLoading, setMightLoading] = useState(true);
  const [rankingLoading, setRankingLoading] = useState(true);
  const dispatch = useDispatch();
  const [youMightLike, setyouMIghtLike] = useState([]);
  const [rankingData, setRankingData] = useState({
    fighters: [],
    status: "",
    message: "",
  });
  const token = JSON.parse(useAuth()?.token);
  const user = JSON.parse(useAuth()?.user);

  //function to fetch you might like
  const FetchMightLike = async () => {
    try {
      const res = await userApi.youMightLike(token);
      if (res?.status === 200) {
        setyouMIghtLike(res?.data?.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setMightLoading(false);
    }
  };

  //function to follow and unfollow fighter
  const followUnfollow = async (fighterId, action) => {
    setLoading(true);
    try {
      const res = await userApi.followUnfollow(token, fighterId, action);
      // console.log(res);
      if (res?.status === 200) {
        dispatch(
          setFollowed({
            followed: res?.data?.updatedFollower,
          })
        );
        setyouMIghtLike((prev) =>
          prev.filter((element) => element._id !== fighterId)
        );
        return toast.success(res?.data?.message);
      }
    } catch (e) {
      let errorMessage = "";
      if (e.response && e.response.data) {
        errorMessage = e.response.data.message;
      } else {
        errorMessage = e.message;
      }
      return toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  //function to fetch ranking
  async function fetchRanking() {
    try {
      const res = await orderApi.fetchRanking(token, "Monthly", 4);
      // console.log(res);
      setRankingData({
        fighters: res?.data?.data,
        message: "data Fetched successfully",
        status: "success",
      });
      // console.log(res);
    } catch (e) {
      setRankingData({
        fighters: [],
        message: e?.response?.data?.message || e?.response?.data?.error,
        status: "error",
      });
      // console.log(e);
    } finally {
      setRankingLoading(false);
    }
  }

  async function LoadFavouriteFighter() {
    userApi
      .getFollowedFighter(token)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(
            setFollowed({
              followed: res?.data?.followList,
            })
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    LoadFavouriteFighter();
    fetchRanking();
    FetchMightLike();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="right-aside">
      <div className="right-card">
        {/* card for you may like */}
        <div className="card-grey">
          <div className="card-content">
            <div className="card-head mb-3 mt-2">
              <h5>You Might Like</h5>
            </div>
            {/* celebrity ul list */}
            <div className="celeb-c">
              <ul className="celeb-list m-auto ">
                {/* celebrity list start from here */}
                {migthLoading === true ? (
                  <>
                     <div className="d-flex flex-column gap-4">
                    <div className="d-flex gap-2 RSA-shm ">
                      <div className="div-con">
                        <div className="img as-Shimer"></div>
                      </div>
                      <div className="name as-Shimer"></div>
                      <div className="btn as-Shimer"></div>
                    </div>

                    <div className="d-flex gap-2 RSA-shm ">
                      <div className="div-con">
                        <div className="img as-Shimer"></div>
                      </div>
                      <div className="name as-Shimer"></div>
                      <div className="btn as-Shimer"></div>
                    </div>

                    <div className="d-flex gap-2 RSA-shm ">
                      <div className="div-con">
                        <div className="img as-Shimer"></div>
                      </div>
                      <div className="name as-Shimer"></div>
                      <div className="btn as-Shimer"></div>
                    </div>
                    <div className="d-flex gap-2 RSA-shm ">
                      <div className="div-con">
                        <div className="img as-Shimer"></div>
                      </div>
                      <div className="name as-Shimer"></div>
                      <div className="btn as-Shimer"></div>
                    </div>
                  </div>
                  </>
                ) : (
                  <>
                    {youMightLike?.slice(0, 5)?.map((element) => (
                      <li className="mb-2" key={element._id}>
                        <div className="celebrity-profile row">
                          <Link
                            className="p-0 col-2"
                            to={`${
                              user?.role === "companion"
                                ? `/companion/${element?.userName}`
                                : `/fighter/${element?.userName}`
                            }`}
                          >
                            <img
                              loading="lazy"
                              src={
                                element?.profileImage
                                  ? `${imgBasePath}/${element?.profileImage}`
                                  : "https://media.istockphoto.com/id/1268292259/photo/asian-woman-smile-take-selfie.jpg?s=612x612&w=0&k=20&c=9wN1RoksDOiBCgp3OxnK1T1W8Jz9zT83dvtfU0d0Z8E="
                              }
                              alt="Celebrity Profile"
                              onError={(e) => (e.target.src = defaultImag)}
                              className="celebrity-avatar"
                            />
                          </Link>
                          <Link
                            to={`${
                              user?.role === "companion"
                                ? `/companion/${element?.userName}`
                                : `/fighter/${element?.userName}`
                            }`}
                            className="text-decoration-none col-4"
                          >
                            <div className="celebrity-name text-capitalize">
                              {element?.firstName}
                            </div>
                            <div className="celebrity-role text-capitalize">
                              {element?.promotionCompany}
                            </div>
                          </Link>
                          <button
                            className="follow-button col-4"
                            disabled={loading}
                            onClick={() =>
                              followUnfollow(element?._id, "follow")
                            }
                          >
                            Follow
                          </button>
                        </div>
                      </li>
                    ))}
                    <div className="see-more-btn">
                      <Link
                        to={`${
                          user.role !== "companion" ? "/fighter" : "/companion"
                        }/mightlike`}
                        className="see link-text"
                      >
                        Show More
                      </Link>
                    </div>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="card-grey">
          <div className="card-content">
            <div className="card-head mb-3 mt-2">
              <h5>Rankings</h5>
            </div>
            {/* celebrity ul list */}
            <div className="celeb-c">
              <ul className="celeb-list m-auto ">
                {/* celebrity list start from here */}

                {rankingLoading === true ? (
                  <div className="d-flex flex-column gap-4">
                    <div className="d-flex gap-2 RSA-shm ">
                      <div className="div-con">
                        <div className="img as-Shimer"></div>
                      </div>
                      <div className="name as-Shimer"></div>
                      <div className="btn as-Shimer"></div>
                    </div>

                    <div className="d-flex gap-2 RSA-shm ">
                      <div className="div-con">
                        <div className="img as-Shimer"></div>
                      </div>
                      <div className="name as-Shimer"></div>
                      <div className="btn as-Shimer"></div>
                    </div>

                    <div className="d-flex gap-2 RSA-shm ">
                      <div className="div-con">
                        <div className="img as-Shimer"></div>
                      </div>
                      <div className="name as-Shimer"></div>
                      <div className="btn as-Shimer"></div>
                    </div>
                    <div className="d-flex gap-2 RSA-shm ">
                      <div className="div-con">
                        <div className="img as-Shimer"></div>
                      </div>
                      <div className="name as-Shimer"></div>
                      <div className="btn as-Shimer"></div>
                    </div>
                  </div>
                ) : (
                  <>
                    {rankingData?.fighters?.map((element, index) => (
                      <li className="mb-2" key={element?._id}>
                        <div className="celebrity-profile row">
                          <Link
                            to={`${
                              user?.role === "companion"
                                ? `/companion/${element?.userName}`
                                : `/fighter/${element?.userName}`
                            }`}
                            className="p-0 col-2"
                          >
                            <img
                              loading="lazy"
                              src={
                                element?.profileImage
                                  ? `${imgBasePath}/${element?.profileImage}`
                                  : "https://media.istockphoto.com/id/1268292259/photo/asian-woman-smile-take-selfie.jpg?s=612x612&w=0&k=20&c=9wN1RoksDOiBCgp3OxnK1T1W8Jz9zT83dvtfU0d0Z8E="
                              }
                              alt="Celebrity Profile"
                              className="celebrity-avatar"
                              onError={(e) => (e.target.src = defaultImag)}
                            />
                          </Link>
                          <Link
                            to={`${
                              user?.role === "companion"
                                ? `/companion/${element?.userName}`
                                : `/fighter/${element?.userName}`
                            }`}
                            className="text-decoration-none col-4"
                          >
                            <div className="celebrity-name text-capitalize">
                              {element?.firstName}
                            </div>
                            <div className="celebrity-role text-capitalize">
                              {element?.promotionCompany}
                            </div>
                          </Link>

                          <div className="ranking col-4 text-end">
                            #{index + 1}
                          </div>
                        </div>
                      </li>
                    ))}

                    <div className="see-more-btn">
                      <Link
                        to={`${
                          user.role !== "companion" ? "/fighter" : "/companion"
                        }/ranking`}
                        className="see link-text"
                      >
                        Show More
                      </Link>
                    </div>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightAside;
