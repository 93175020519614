import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BackBTn, EmailIcon } from "../elements/SvgElements";
import { useForm } from "react-hook-form";
import { AuthApi } from "../config/axiosUtils";
import { Slide, toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../features/authSlice";
import { setCart } from "../features/cartSlice";
import OtpVerification from "../components/OtpVerification";
import PasswordConfiormPassword from "../components/PasswordConfiormPassword";
import ForgotPasswordLoaderContainer from "../components/CustomLoader/ForgotPasswordLoaderContainer";

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [resetPassword, setResetPassword] = useState({
    email: "",
    step: 1,
    prevOtpTime: null,
    token: "",
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const from = location.state?.from?.pathname || "/";
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const forgotPassword = async (data) => {
    try {
      setLoading(true);
      if (resetPassword.step === 1) {
        const date = new Date();
        const response = await AuthApi.forgotPassword(data);
        setResetPassword({
          ...resetPassword,
          email: data.email,
          step: 2,
          prevOtpTime: date.getTime(),
        });
        // console.log(response);
      }
      if (resetPassword.step === 2) {
        let otp = "";
        otp = data.otp1 + data.otp2 + data.otp3 + data.otp4;

        otp = parseInt(otp, 10); // Specify the base as 10

        const response = await AuthApi.varifyOtp({
          email: resetPassword.email,
          otp,
        });
        setResetPassword({
          ...resetPassword,
          step: 3,
          token: response.data.resetToken,
        });
      }

      if (resetPassword.step === 3) {
        const res = await AuthApi.resetPassword({
          token: resetPassword.token,
          password: data.password,
        });

        toast.success(data.message);
        dispatch(
          login({
            user: JSON.stringify(res.data.user),
            token: JSON.stringify(res.data.token),
          })
        );
        dispatch(
          setCart({
            cart: res?.data?.user?.cart,
          })
        );
        localStorage.setItem("user-id", res.data.token);
        navigate(from, { replace: true });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      // console.log(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const handleOtpInput = (event, index) => {
    console.log("otp input");
    const { value, maxLength } = event.target;
    if (value.length >= maxLength) {
      if (index < otpRefs.length - 1) {
        otpRefs[index + 1].current.focus();
      }
    }
    setValue(`otp${index + 1}`, value);
  };

  const handleOtpKeyup = (event, index) => {
    console.log("otp key up");
    if (event.code === "Backspace" && event.target.value === "") {
      if (index > 0) {
        otpRefs[index - 1].current.focus();
      }
    }
  };

  const handleOtpPaste = (event, index) => {
    console.log("otp opt key paste");
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain").slice(0, 4);
    pasteData.split("").forEach((char, idx) => {
      if (index + idx < otpRefs.length) {
        otpRefs[index + idx].current.value = char;
        setValue(`otp${index + idx + 1}`, char);
        if (index + idx < otpRefs.length - 1) {
          otpRefs[index + idx + 1].current.focus();
        }
      }
    });
  };

  useEffect(() => {
    if (resetPassword.step === 2) {
      otpRefs.forEach((ref, index) => {
        register(`otp${index + 1}`, {
          required: {
            value: true,
            message: "OTP is required",
          },
          pattern: {
            value: /^[0-9]$/,
            message: "Invalid OTP digit",
          },
        });
      });
    }
  }, [register]);

  const isOtpComplete =
    watch("otp1") && watch("otp2") && watch("otp3") && watch("otp4");

  return (
    <div className="position-relative">
      <div className="form-start-tab">
        <div className="form-brand-ct">
          {resetPassword.step === 1 && (
            <>
              <span className="back-i-btn">
                <button
                  className="backbtn"
                  type="button"
                  //   onClick={handleBackButtonClick}
                >
                  <Link to="/signin">{BackBTn}</Link>
                </button>
              </span>
              {/* forgot password here in content start from here  */}
              <div className="login-view mt-4 text-left">
                <h2 className="sub-head">
                  Reset your password <small>🔑</small>
                </h2>
                <p className="para mt-2 text-left">
                  Please enter your email and we will send an OTP code in the
                  next step to reset your password.
                </p>
              </div>
              {/* ...forgot password content form */}
              <form
                className="user-login-form"
                onSubmit={handleSubmit(forgotPassword)}
              >
                <div className="form-start-login">
                  <div className="form-brand">
                    {/* form start from here */}
                    <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                      {/* form input one for log email */}
                      <div className="form-group text-left">
                        <label className="form-head mb-2" htmlFor="log-mail">
                          Email
                        </label>
                        <div
                          className={`inputWithIcon email-input ${
                            errors?.email ? "error-border" : ""
                          } `}
                        >
                          <input
                            type="text"
                            id="login-email"
                            className={`${
                              errors?.email ? "error-placeholder" : ""
                            }`}
                            placeholder="Enter your email"
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Email is required",
                              },
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Enter a Valid Email",
                              },
                            })}
                          />
                          {EmailIcon}
                        </div>
                        {errors?.email && (
                          <small className="error-message">
                            {errors?.email?.message}
                          </small>
                        )}
                      </div>
                      {/* area end for info text */}
                      <div className="account-switch">
                        {/* btn-for -sign-in */}

                        <button
                          className={`long-btn-sign-in mt-5 mx-auto ${
                            loading ? "long-btn-sign-in-disabled" : null
                          }`}
                          disabled={loading}
                          type="submit"
                        >
                          {!loading ? "Continue" : "loading..."}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
          {resetPassword.step === 2 && (
            <OtpVerification
              handleSubmit={handleSubmit}
              forgotPassword={forgotPassword}
              loading={loading}
              register={register}
              errors={errors}
              otpRefs={otpRefs}
              handleOtpInput={handleOtpInput}
              handleOtpKeyup={handleOtpKeyup}
              handleOtpPaste={handleOtpPaste}
              isOtpComplete={isOtpComplete}
              resetPassword={resetPassword}
              setLoading={setLoading}
              setResetPassword={setResetPassword}
            />
          )}
          {resetPassword.step === 3 && (
            <PasswordConfiormPassword
              handleSubmit={handleSubmit}
              forgotPassword={forgotPassword}
              loading={loading}
              register={register}
              errors={errors}
              watch={watch}
            />
          )}
        </div>
      </div>
      {loading && resetPassword.step === 1 && (
        <div className="over-lay active">
          <ForgotPasswordLoaderContainer
            title={"Sending OTP"}
            message={"you will we redirected to verify otp!"}
          />
        </div>
      )}
      {loading && resetPassword.step === 2 && (
        <div className="over-lay active">
          <ForgotPasswordLoaderContainer
            title={"Verifiying OTP"}
            message={"you will we redirected to reset password page!"}
          />
        </div>
      )}
      {loading && resetPassword.step === 3 && (
        <div className="over-lay active">
          <ForgotPasswordLoaderContainer
            title={"Reseting Password"}
            message={"you will we redirected to Home page"}
          />
        </div>
      )}
      {/* test*/}

      <ToastContainer
        className="custom-toast-container"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </div>
  );
};

export default ForgotPassword;
